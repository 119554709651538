.headerPlaceholder > span > span {
  width: 100%;
  height: 203px;
}

.searchbarPlaceholder > span > span {
  margin: 25px 15px 0;
  height: 44px;
  width: 95%;
}

.totalcountPlaceholder > span > span {
  margin: 25px 15px 0;
  height: 16px;
  width: 85px;
}

.productsListPlaceholder {
  margin: 25px 15px 0;
}

.productPlaceholder {
  margin-bottom: 40px;
}

.productHeroPlaceholder > span > span {
  margin-bottom: 10px;
  height: 185px;
}

.productTitlePlaceholder > span > span {
  width: 90%;
  height: 30px;
  margin-bottom: 15px;
}

.productTextPlaceholder > span > span {
  width: 50%;
  height: 20px;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
}

.productTextPlaceholder > span > span:first-child {
  margin-bottom: 10px;
}

@media (min-width: 685px) {
  .headerPlaceholder > span > span {
    height: 320px;
  }

  .totalcountPlaceholder {
    text-align: center;
  }

  .searchbarPlaceholder {
    display: none;
  }

  .productsListPlaceholder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px 80px 0;
    column-gap: 30px;
    row-gap: 80px;
  }

  .productPlaceholder {
    width: 297px;
    height: 400px;
  }
}
