/*
 * Quantcast Choice (GDPR Consent Management)
 *
 * https://www.quantcast.com/gdpr/quantcast-choice-self-serve/
 */

$cookie-banner-height-desktop: 3.26rem; // The height of the cookie banner on desktop
$gray-mid: #4a4a4a;
$red: #d31a22;
$black: #000000;
$white: #ffffff;
$black-1: #1a1a1a;
$gray-light-mid: #d8d8d8;
$gray-light: #7d7d7d;

$border-1: 1px; // default
$border-2: 2px;
$border-3: 3px;
$border: $border-1 solid $gray-light-mid;
$border-radius: 4px;

button[mode="primary"] {
  background-color: $black-1 !important;
  border-color: $black-1 !important;
  color: $white !important;
}

button[mode="secondary"] {
  background-color: $white !important;
  border-color: $gray-light !important;
  color: $gray-mid !important;
}

button[mode="secondary"]:hover {
  border-color: $black-1 !important;
  color: $black-1 !important;
}

button[mode="primary"]:hover {
  background-color: $gray-mid !important;
  border-color: $gray-mid !important;
}

.qc-cmp2-home-button,
.qc-cmp2-expandable-list a,
button[mode="link"] {
  color: $red !important;
}

.qc-cmp2-main #qc-cmp2-ui .qc-cmp2-scrollable-section::-webkit-scrollbar-thumb {
  background: $red !important;
}

#qc-cmp2-ui
  .qc-cmp2-consent-info
  .qc-cmp2-publisher-logo-container
  .qc-cmp2-home-button
  svg
  use {
  fill: $red;
}

.qc-cmp2-ui,
.qc-cmp2-ui .qc-cmp2-main-messaging,
.qc-cmp2-ui .qc-cmp2-messaging,
.qc-cmp2-ui .qc-cmp2-beta-messaging,
.qc-cmp2-ui .qc-cmp2-title,
.qc-cmp2-ui .qc-cmp2-sub-title,
.qc-cmp2-ui .qc-cmp2-purpose-info,
.qc-cmp2-ui .qc-cmp2-table,
.qc-cmp2-ui .qc-cmp2-table-header,
.qc-cmp2-ui .qc-cmp2-vendor-list,
.qc-cmp2-ui .qc-cmp2-vendor-list-title {
  color: $black !important;
}

.qc-cmp2-ui a,
.qc-cmp2-ui .qc-cmp2-alt-action,
.qc-cmp2-toggle-status {
  color: $red !important;
}

.qc-cmp2-ui {
  background-color: $white !important;
}

.qc-cmp2-publisher-purposes-table .qc-cmp2-table-header {
  background-color: $white !important;
}

.qc-cmp2-publisher-purposes-table .qc-cmp2-table-row {
  background-color: $white !important;
}

.qc-cmp2-vendor-list .qc-cmp2-vendor-row {
  background-color: $white !important;
}

.qc-cmp2-vendor-list .qc-cmp2-vendor-row-header {
  background-color: $white !important;
}

.qc-cmp2-table {
  border: 1px solid $black !important;
}

.qc-cmp2-table-row {
  border-top: 1px solid $black !important;
}

.qc-cmp2-table-row:last-child {
  border-bottom: 1px solid $black !important;
}

.qc-cmp2-toggle-status {
  color: $black !important;
}

.qc-cmp2-arrow-down {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='$black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E %3Cpolyline points='2 5 8 11 14 5'/%3E %3C/svg%3E")
    center no-repeat;
}

.qc-cmp2-small-toggle.qc-cmp2-toggle-on,
.qc-cmp2-toggle.qc-cmp2-toggle-on,
#qc-cmp2-persistent-link {
  background-color: $red !important;
  /*border-color: $red !important;*/
}

.qc-cmp2-small-toggle.qc-cmp2-toggle-off,
.qc-cmp2-toggle.qc-cmp2-toggle-off {
  background-color: $gray-light-mid !important;
}

.qc-cmp2-toggle {
  border: $border !important;
}

/* Added to original styling to reverse order of accept and decline buttons on all screensizes and platforms */
@media screen and (min-width: 551px) {
  .qc-cmp2-buttons .qc-cmp2-button {
    order: 1;
  }

  .qc-cmp2-buttons .qc-cmp2-secondary-button {
    order: 2;
  }

  .qc-cmp2-buttons .qc-cmp2-alt-action {
    order: 3;
  }
}

@media screen and (max-width: 550px) {
  /* Fix for buttons on mobile being squashed up to/overlapping text above them */
  .qc-cmp2-buttons.qc-cmp2-primary-buttons {
    height: 10.8rem !important;
  }

  /* Fix to keep CMP panel at bottom of page even on mobile devices */
  .qc-cmp2-ui {
    margin-bottom: inherit !important;
  }

  /* Fix for quashed buttons at bottom of Purposes and Vendors screens */
  .qc-cmp2-nav-bar.qc-cmp2-bottom {
    height: 10rem !important;
  }

  /* Fix for large empty space at top of panel where logo would go */
  .qc-cmp2-nav-bar.qc-cmp2-top .qc-cmp2-nav-bar-publisher-logo-container {
    min-height: 10px !important;
  }
}
