.container {
  background-color: #1a1a1a;
  width: 376px;
  height: 100vh;
  position: absolute;
  left: 0;

  h2 {
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: normal;
    color: #fff;
    margin: 77px 120px 0;
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 33px 20px 0 100px;
  }

  li {
    margin: 20px 0;
  }

  a {
    border-left: 5px solid transparent;
    padding: 20px;
    font-family: Roboto;
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    padding: 20px;
    list-style: none;
    text-decoration: none;
    cursor: pointer;
  }

  a.active {
    border-left-color: #d31a22;
    font-weight: bold;
  }

  a:hover {
    font-weight: bold;
  }
}
