.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;

  pointer-events: all;
}

.iframe {
  min-height: 678px;
  width: calc(100vw - 40px);
}

@media (min-width: 500px) {
  .iframe {
    width: 428px;
  }
}
