@import "./variables.scss";

@mixin mobileS {
  @media (max-width: $mobileS) {
    @content;
  }
}

@mixin mobileM {
  @media (max-width: $mobileM) {
    @content;
  }
}

@mixin mobileL {
  @media (max-width: $mobileL) {
    @content;
  }
}

@mixin tablet($property: max-width) {
  @media ($property: $tablet) {
    @content;
  }
}

@mixin desktop($property: max-width) {
  @media ($property: $desktop) {
    @content;
  }
}

@mixin desktopM($property: max-width) {
  @media ($property: $desktopM) {
    @content;
  }
}

@mixin desktopL($property: max-width) {
  @media ($property: $desktopL) {
    @content;
  }
}

@mixin desktopXL($property: max-width) {
  @media ($property: $desktopXL) {
    @content;
  }
}
