@import "../../../styles";

$padding: 1.5rem;
$background-color: #f2f2f2;
$margin-offset: 30px;
$margin-offset-mobile: 20px;

.root {
  display: flex;
  justify-content: center;
  padding: $padding;

  background-color: $background-color;

  @include tablet {
    flex-direction: column;
    flex-flow: column-reverse;
    padding: 0;
  }
}

.forms,
.aside {
  min-width: 0;
}

.forms {
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
  width: 625px;
  margin-right: $margin-offset;

  form:last-of-type,
  form:first-of-type {
    @include tablet {
      margin-top: $margin-offset-mobile;
      margin-bottom: $margin-offset-mobile;
    }
  }

  form:last-of-type {
    margin-top: $margin-offset;
  }

  form:first-of-type {
    margin-bottom: $margin-offset;
  }

  @include tablet {
    width: 100%;

    form:first-child {
      margin-top: $margin-offset-mobile;
    }

    form:last-of-type {
      margin-bottom: $margin-offset-mobile;
    }
  }
}

.aside {
  flex-shrink: 0;
  width: 406px;

  @include tablet {
    width: 100%;
  }
}

.summary {
  position: sticky;
  top: $margin-offset;

  @include tablet {
    position: unset;
    margin-top: $margin-offset-mobile;
  }
}

.submit {
  display: none;

  background-color: #ffffff;

  @include tablet {
    display: unset;
    margin-bottom: $margin-offset-mobile;
    padding-bottom: 20px;
  }
}

.hidden {
  display: none;
}
