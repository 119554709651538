.container {
  background-color: #1a1a1a;
  width: 100%;
  height: 117px;
  left: 0;

  h2 {
    margin: 30px 0px 21px 15px;
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: normal;
    color: #fff;
  }

  ul {
    padding: 0;
    display: flex;
    margin-left: 15px;
  }

  li {
    margin-right: 29px;
  }

  a {
    border-bottom: 4px solid transparent;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    cursor: pointer;
  }

  a.active {
    border-bottom-color: #d31a22;
    font-weight: bold;
    padding-bottom: 11px;
  }

  a:hover {
    font-weight: bold;
  }
}
