@import "../../../styles";

$padding: 1.5rem;
$paddingMobile: 16px;
$marginMobile: 40px;

.root {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-left: $padding;
  padding-right: $padding;
  padding-top: 50px;

  background-color: #f2f2f2;

  @include mobileL {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobileL {
    width: 100%;
  }
}

.button {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  button {
    margin-top: $marginMobile;
    margin-bottom: $marginMobile;
    width: 300px;
    height: 50px;

    color: #ffffff;
    font-size: 16px;
    font-weight: bold;

    background-color: #1a1a1a;
    cursor: pointer;

    &:hover {
      background-color: $error-color;
    }
  }

  @include mobileL {
    margin-top: 20px;

    background-color: #ffffff;
  }
}
