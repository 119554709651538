@import "../../../styles";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;

  -webkit-font-smoothing: antialiased;

  pointer-events: all;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.375rem;

  background-color: #ffffff;
}

.title {
  margin-top: 0;
  margin-bottom: 0;

  font-size: 28px;
  font-weight: 400;

  text-align: center;
  color: $error-color;
}

.status::before {
  content: "Error:";
  margin-right: 4px;
}

.status {
  margin-top: 4px;
  margin-bottom: 0;

  font-size: 16px;
  font-weight: 400;

  text-align: center;
  color: $error-color;
}

.body {
  width: 415px;

  font-size: 16px;
  font-family: "Roboto";
  text-align: center;
  line-height: 1.3;
}

.button {
  margin-bottom: 50px;
  margin-top: 24px;
  width: 346px;
  height: 60px;

  background-color: $error-color;

  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.95);

  cursor: pointer;
}

.content hr {
  width: 10%;
  margin-bottom: 45px;

  border-color: #ececec;
}

.support {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Roboto";
  font-weight: 400;

  line-height: 1.3;

  span {
    font-size: 24px;
  }
}

.supportContact {
  padding-left: 12px;

  color: $error-color;
  font-size: 14px;
  text-decoration: none;
}
