html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  position: relative;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@font-face {
  font-family: "FranklinGothicLTPro-DmCm";
  src: url("/fonts/FranklinGothicLTPro/FranklinGothicLTPro-DmCm.woff2");
}

@font-face {
  font-family: "FranklinGothicLTPro-DmCm";
  src: url("/fonts/FranklinGothicLTPro/FranklinGothicLTPro-DmCm.woff2");
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto/Roboto-Regular.woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto/Roboto-Medium.woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto/Roboto-Bold.woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
