// Dimensions lifted from to-component-library/.../newsletter-signup-widget/device-size.ts

$mobileS: 320px;
$mobileM: 375px;
$mobileL: 428px;
$tablet: 768px;
$desktop: 900px;
$desktopM: 1000px;
$desktopL: 1100px;
$desktopXL: 1300px;

$error-color: #d31a22;
